<template>
  <div class="VirtualTree">
    <VirtualList
      ref="virtualListRef"
      :itemHeight="nodeHeight"
      :itemKey="propFieldNames.key"
      :data="visibleData" :isCustomization="isCustomization"
    >
      <template #empty>
        <div class="empty-block">
          <span class="empty-text">{{emptyText}}</span>
        </div>
      </template>

      <template #default="{ item: node }">
        <div
          :style="{
             paddingLeft: node.level * indent + 'px',
            }"
          :class="[
          'VirtualNode',
          (getNodeKey(node) === currentKey) && 'SelectedNode',
          ]"
          @click.stop="onNodeClick(node)"
          @dblclick.stop="onNodeDblclick(node)"
        >
          <!-- 展开icon -->
          <TreeNodeSwitcher
            :size="14"
            :visible="!node.isLeaf"
            :expanded="node.expanded"
            @expand="onExpand(node)"
          ></TreeNodeSwitcher>
          <!-- 复选框 -->
          <span @click.stop="1===1">
            <el-checkbox
              v-if="showCheckbox&&node.checkable"
              class="TreeNodeCheckbox"
              :disabled="node.disabled"
              :value="node.checked"
              :indeterminate="node.indeterminate"
              @change="onCheckChange(node, $event)"
            ></el-checkbox>
          </span>

          <div :style="vNodeContentStyle" class="vNodeContent">
            <slot v-if="$scopedSlots.default" :node="node" :data="node.data"></slot>
            <template v-else>{{ getNodeLabel(node) }}</template>
          </div>
        </div>
      </template>
    </VirtualList>
  </div>
</template>

<script>
import { omit, throttle, isObject, merge, } from 'lodash';
import VirtualList from '@/components/VirtualList';
import TreeNodeSwitcher from './TreeNodeSwitcher.vue';
import { depthTree } from '@/utils/treeHelper';
import { sleepIf, sleep } from '@/utils/sleep';

// 节点默认属性
const defaultNodeProps = {
  // 是否显示在页面上
  visible: false,
  // 是否展开
  expanded: false,
  // 是否显示复选框
  checkable: false,
  // 复选框是否勾选
  checked: false,
  // 设置禁用状态
  disabled: false,
  // 半选状态
  indeterminate: false,

  // 父节点
  parentNode: null,
  // 父节点key
  parentKey: '',
  // 深度
  level: 0,
  // 是否是叶子节点
  isLeaf: true,
  // 父节点路径
  pathParentKeys: [],
};

export default {
  name: 'Tree',
  components: {
    VirtualList,
    TreeNodeSwitcher,
  },
  emits: [

    // tree加载完成
    'loaded',
    // 节点被点击时的回调
    'node-click',
    // 双击事件
    'node-dblclick',
    // 当某一节点被鼠标右键点击时会触发该事件
    'node-contextmenu',
    // 节点选中状态发生变化时的回调
    'check-change',
    // 当复选框被点击的时候触发
    'check',
    // 当前选中节点变化时触发的事件
    'current-change',
    // 节点被展开时触发的事件
    'node-expand',
    // 节点被关闭时触发的事件
    'node-collapse',

  ],
  props: {
    // 内容为空的时候展示的文本
    emptyText: {
      type: String,
      default: '--'
    },
    // 相邻级节点间的水平缩进
    indent: {
      type: Number,
      default: 16,
    },
    // 传入`data`,生成对应的树结构
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否默认展开全部
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    /**
     * 展开子节点的时候是否自动展开父节点
     */
    autoExpandParent: {
      type: Boolean,
      default: true,
    },
    // 默认展开的节点的 key 的数组
    defaultExpandKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认勾选节点key数组
    defaultCheckedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认禁用复选框节点key数组
    defaultDisabledKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否在点击节点的时候展开或者收缩节点
    expandOnClickNode: {
      type: Boolean,
      default: false,
    },
    // 是否在点击节点的时候选中节点
    checkOnClickNode: {
      type: Boolean,
      default: false,
    },
    // 是否在节点前添加复选框
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    // 是否取消父子节点关联
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    // 节点行高, 传入此参数以开启虚拟滚动，
    nodeHeight: {
      type: Number,
      default: 26,
    },
    fieldNames: {
      type: Object,
      default() {
        return null;
      }
    },
    // 根节点的父节点key, 表示改节点为根节点
    // 一般为`null`或`-1`
    rootParentKey: {
      type: [String, Number],
      default: null,
    },
    /**
     * 点击事件延迟事件
     * 有双击事件, 可以设置
     * 0: 不延迟
     */
    clickDelay: {
      type: Number,
      default: 0,
    },
    visibleFilter: {
      type: Function,
      default: null,
    },
    checkedData: {
      type: Object,
      default: null
    },

    // 满值
    isEffluence: {
      type: Boolean,
      default: false
    },
    // 定制选项 默认全选车组
    isCustomization: {
      type: Boolean,
      default: false
    }
  },
  data() {

    // 有双击事件时, 单击事件延迟执行
    this.delayClickTimerId = null;

    return {
      treeData: [],

      // 当前选中的节点key
      currentKey: '',
      // 所有选中节点keys TODO: 可支持多选
      selectedKeys: [],
      checkedVEH: null,
      clickTime: null
    };
  },
  computed: {
    propFieldNames() {
      const { fieldNames } = this;
      return {
        key: 'key',
        label: 'label',
        children: 'children',
        parentKey: 'parentKey',
        ...(fieldNames || {})
      };
    },

    visibleData() {
      const { treeData, visibleFilter } = this;
      return treeData.filter(node => (!visibleFilter || visibleFilter(node)) && node.visible);
    },
    vNodeContentStyle() {
      const { showCheckbox } = this;
      return {
        width: `calc(100% - ${ showCheckbox ? 55 : 25 }px)`
      };
    }
  },
  watch: {
    data() {
      this.initTree();
    },
    checkedData: function (v) {
      if (v) {
        this.checkedVEH = v;
      }
    },
    // TODO: 未验证
    defaultDisabledKeys(disabledKeys) {
      // const { treeData } = this;
      // treeData
      //   .forEach(node => {
      //     node.disabled == disabledKeys(this.getNodeKey(node));
      //   });
    },
  },
  beforeMount() {
    this.initTree = throttle(this.initTree, 1000).bind(this);

    this.initTree();

  },
  mounted() {

  },
  beforeDestroy() {
    this.clearClick();
  },
  methods: {
    // 根据`fieldNames`的配置获取`key`的值
    getNodeKey(node) {
      const { propFieldNames } = this;
      let { key } = propFieldNames;
      return node[key];
    },
    // 根据`fieldNames`的配置获取`parentKey`的值
    getParentKey(node) {
      const { propFieldNames } = this;
      let { parentKey } = propFieldNames;
      return node[parentKey];
    },
    // 根据`fieldNames`的配置获取`label`的值
    getNodeLabel(node) {
      const { propFieldNames } = this;
      let { label } = propFieldNames;
      return node[label];
    },

    /**
     * 更新节点数据
     */
    updateNodeData(key, data) {
      const node = this.getNode(key);
      if (!node) return;
      for (let k in data) {
        node.data[k] = data[k];
      }
    },
    /**
     * 更新节点装
     */
    updateNode(key, updateNode) {
      const node = isObject(key) ? key : this.getNode(key);
      if (!node) return;
      for (let k in updateNode) {
        node[k] = updateNode[k];
      }
    },

    // 获取所有节点
    getAllNodes() {
      const { treeData } = this;
      return treeData;
    },

    /**
     * 通过key获取节点
     */
    getNode(key) {
      // TODO: 如果性能有问题, 可尝试使用Map存储
      const { treeData } = this;
      return treeData.find(node => this.getNodeKey(node) === key);
    },

    /**
     * 获取子节点
     * @param {boolean} allSub - 是否获取所有子节点
     */
    getSubNodes(node, allSub = false) {
      const { treeData } = this;

      const nodeKey = this.getNodeKey(node);

      const subNodes = [];
      let i = node.index + 1;

      while (i < treeData.length) {
        const nextNode = treeData[i];
        const { pathParentKeys } = nextNode;

        if (!pathParentKeys.includes(nodeKey)) {
          break;
        }

        if (allSub || pathParentKeys[pathParentKeys.length - 1] === nodeKey) {
          subNodes.push(nextNode);
        }
        i++;
      }
      return subNodes;
    },

    /**
     * 设置节点`diabled`状态
     */
    setDisabledNode(key, disabled = false) {
      const node = isObject(key) ? key : this.getNode(key);
      if (!node) return;
      node.disabled = disabled;
    },

    /**
    * 通过节点Key设置节点展开状态
    * @param {Object} key - 节点key
    * @param {boolean} expanded - 展开状态
    * @param {boolean} autoExpandParent - 展开时, 是否展开父节点
    */
    setExpandedKey(key, expanded = true, autoExpandParent = this.autoExpandParent) {
      const node = this.getNode(key);
      if (node) {
        this.setExpanded(node, expanded, autoExpandParent);
      }
    },
    /**
     * 通过节点设置节点展开状态
     * @param {Object} node - 节点
     * @param {boolean} expanded - 展开状态
     * @param {boolean} autoExpandParent - 展开时, 是否展开父节点
     */
    setExpanded(node, expanded = true, autoExpandParent = this.autoExpandParent) {

      node.expanded = expanded;
      node.visible = true;
      // 展开时是否自动展开父节点
      if (expanded && autoExpandParent) {
        let parentNode = node.parentNode;
        while (parentNode) {
          parentNode.expanded = true;
          parentNode.visible = true;

          // 直接子节点显示
          this.getSubNodes(parentNode)
            .forEach(subNode => {
              subNode.visible = true;
            });

          parentNode = parentNode.parentNode;
        }
      }

      // 设置子节点显示状态
      this.getSubNodes(node, true)
        .forEach(subNode => {
          // 节点显示: 父节点显示并且父节点展开
          subNode.visible = expanded
            && subNode.parentNode?.visible
            && subNode.parentNode?.expanded;
        });
    },
    /**
     * 获取展开的节点数组
     */
    getExpandedNodes() {
      const { treeData } = this;
      return treeData.filter(p => p.expanded);
    },

    /**
     * 获取当前被选中节点的 key,
     * 若没有节点被选中则返回 null
     */
    getCurrentKey() {
      const { currentKey } = this;
      return currentKey || null;
    },
    /**
     * 获取当前被选中节点的 `node`,
     * 若没有节点被选中则返回 null
     */
    getCurrentNode() {
      const { currentKey } = this;
      return this.getNode(currentKey) || null;
    },
    /**
     * 通过 key 设置某个节点的当前选中状态
     * 若为 null 则取消当前高亮的节点
     */
    setCurrentKey(key = null, intoView = true) {
      this.currentKey = key;

      // 滚动到可视区
      if (intoView) {
        const { virtualListRef } = this.$refs;
        const node = this.getNode(key);
        if (!node) return;

        if (!node.expanded) {
          this.setExpanded(node, true, true);
        }

        virtualListRef?.scrollIntoView(node);
        this.onCheckChange(node, !node.checked);
      }
    },
    /**
     * 通过 node 设置某个节点的当前选中状态
     */
    setCurrentNode(node, intoView = true) {
      const key = this.getNodeKey(node);
      this.setCurrentKey(key, intoView);
    },

    /**
     * 返回目前被选中的节点所组成的数组
     * leafOnly: `true` 只是叶子节点
     * includeHalfChecked: `true` 包含半选节点
     */
    getCheckedNodes(leafOnly = false, includeHalfChecked = false) {
      const { treeData } = this;
      return treeData.filter(p =>
        (!leafOnly || p.isLeaf) // 是否只是叶子节点
        && (
          includeHalfChecked ? (p.checked || p.indeterminate) // 是否包含半选节点
            : p.checked
        )
      );
    },
    /**
     * 设置节点的勾选状态
     */
    setCheckedNodes(nodes = [], checked = false) {

      if (!Array.isArray(nodes)) throw new Error('nodes is not Array');
      nodes
        .forEach(node => this.setChecked(node, checked, true));
    },
    /**
     * 返回目前被勾选的节点的 key 所组成的数组
     * leafOnly: `true` 只勾选的叶子节点
     */
    getCheckedKeys(leafOnly = false) {
      return this.getCheckedNodes(leafOnly)
        .map(p => this.getNodeKey(p));
    },
    /**
     * 通过 keys 设置目前勾选的节点
     */
    setCheckedKeys(keys, checked = false) {
      if (!Array.isArray(keys)) return;
      keys
        .forEach(key => this.setChecked(this.getNode(key), checked, true));
    },
    /**
     * 通过 key / node 设置某个节点的勾选状态
     */
    setChecked(node, checked, checkStrictly = this.checkStrictly) {
      node.checked = checked;
      node.indeterminate = false;

      if (!checkStrictly) {
        // 设置所有子节点复选框状态
        this.getSubNodes(node, true)
          .forEach(subNode => {
            if (subNode.disabled || !subNode.checkable) return;
            subNode.checked = checked;
            subNode.indeterminate = false;
          });

        // 设置所有父节点复选框状态
        let parentNode = node.parentNode;
        while (parentNode) {
          if (!parentNode.disabled && parentNode.checkable) {
            const children = this.getSubNodes(parentNode);

            // 子节点有一个没有勾选的, 父节点`checked`都为`false`
            const noChecked = children.some(p => p.checked === false);
            parentNode.checked = !noChecked;

            // 如节点没有勾选, 则子节点有一个勾选, 或`indeterminate`为 `true`, 父节点`indeterminate`为`true`
            parentNode.indeterminate = !parentNode.checked && children.some(p => p.checked || p.indeterminate);
          }
          parentNode = parentNode.parentNode;
        }
      }
    },

    /**
     * 节点全选/取消全选
     */
    setCheckedAll(checked = false) {
      if (checked) {
        const { treeData } = this;
        treeData
          .forEach(node => {
            if (node.disabled || !node.checkable) return;
            this.setChecked(node, true, true);
          });
      } else {
        this.getCheckedNodes(false, true)
          .forEach(node => {
            this.setChecked(node, false, true);
          });
      }
    },

    // 展开事件
    onExpand(node) {

      const expanded = !node.expanded;

      this.setExpanded(node, expanded, false);

      const allExpandeds = this.getExpandedNodes().map(p => p.data);

      this.$emit('node-expand', node.data, expanded, node, allExpandeds);
    },
    // 复选框勾选事件
    onCheckChange(node, checked) {
      if (node.disabled) {
        return false;
      };
      // const checked = !node.checked;
      !node.maxNum && !this.isEffluence ? this.setChecked(node, checked) : '';

      const allCheckeds = this.getCheckedNodes().map(p => p.data);

      this.$emit('check-change', node.data, checked, node, allCheckeds);
      this.$nextTick(function () {
        this.$emit('check-change-group', this.showCheckbox ? this.visibleData.filter(val => val.checked === true) : [node], checked);
      });
    },
    onNodeClicks(node) {
      this.onCheckChange(node, true);
      this.clearClick();
      this.currentKey = this.getNodeKey(node);
    },
    // 节点点击
    onNodeClick(node) {
      clearTimeout(this.clickTime);
      this.clickTime = setTimeout(() => {
        this.onCheckChange(node, !node.checked);
        const { clickDelay, checkOnClickNode, showCheckbox } = this;
        this.clearClick();
        this.currentKey = this.getNodeKey(node);
        // 有双击事件时, 延迟执行
        const emitClick = () => {
          this.$emit('node-click', node.data, node);

          if (showCheckbox
            && checkOnClickNode
            && node.checkable
            && !node.disabled
            && !node.checked) {
            this.onCheckChange(node, !node.checked);
          }
        };

        if (clickDelay > 0) {
          this.delayClickTimerId = setTimeout(() => emitClick(), clickDelay);
        } else {
          emitClick();
        }
      }, 300);
    },
    /**
     * 双击事件
     */
    onNodeDblclick(node) {
      clearTimeout(this.clickTime);
      this.clearClick();
      this.$emit('node-dblclick', node.data, node);
    },

    setTreeData() {
      const { propFieldNames, rootParentKey, data, defaultExpandAll, checkedVEH } = this;

      const pathParentNodes = [];
      const treeData = [];
      for (let i = 0; i < data.length; i++) {

        const item = data[i];

        // 节点key
        const nodeKey = this.getNodeKey(item);
        // 父节点key
        let parentKey = this.getParentKey(item) || rootParentKey;
        // 父节点
        let parentNode = null;
        // 下一个节点(i + 1)
        let nextItem = null;

        while (pathParentNodes.length) {
          parentNode = pathParentNodes[pathParentNodes.length - 1];
          if (this.getNodeKey(parentNode) === parentKey) break;
          pathParentNodes.pop();
          parentNode = null;
        }

        if (i < data.length - 1) nextItem = data[i + 1];

        const treeNode = {
          // 下标
          index: i,
          [propFieldNames.key]: nodeKey,
          [propFieldNames.parentKey]: parentKey,
          [propFieldNames.label]: this.getNodeLabel(item),

          ...this.mergeNode(item),

          data: item,

          parentNode,
          pathParentKeys: parentNode
            ? [...parentNode.pathParentKeys, parentKey]
            : [],
          level: parentNode ? parentNode.level + 1 : 0,
        };
        if (checkedVEH && checkedVEH.vehicleId == item.V) {
          treeNode.checked = true;
        } else if (checkedVEH) {
          treeNode.checked = false;
        }

        // 根节点永远显示
        if (parentKey === rootParentKey) {
          treeNode.visible = true;
        }

        // 父节点展开, 字节默认显示
        if (!defaultExpandAll && parentNode && parentNode.expanded) {
          treeNode.visible = true;
        }

        // 是否是叶子节点: 下一个节点的`parentKey`与当前节点的`key`不相等
        treeNode.isLeaf = !nextItem
          || this.getParentKey(nextItem) !== nodeKey;

        if (!treeNode.isLeaf) {
          // 不是叶子节点, 意味着该节点会作为`parentNode`, 被子节点使用到
          pathParentNodes.push(treeNode);
        }

        treeData.push(treeNode);

        // TODO: 给`parentNode`添加`children`
      }
      this.checkedVEH = null;
      this.treeData = treeData;
    },

    initTree() {
      this.setTreeData();
      this.emitLoaded();
    },

    /**
     * 合并默认的,全局的`node`数据
     */
    mergeNode(data) {
      const {
        defaultExpandAll,
        showCheckbox,
        defaultCheckedKeys,
        defaultExpandKeys,
        defaultDisabledKeys,
      } = this;
      const key = this.getNodeKey(data);

      // 禁用
      const disabled = defaultDisabledKeys.includes(key);

      return merge(
        { ...defaultNodeProps },
        {
          checkable: showCheckbox,
          expanded: defaultExpandKeys.includes(key) || defaultExpandAll,
          visible: defaultExpandAll,
          checked: disabled ? false : defaultCheckedKeys.includes(key),
          disabled
        },
        data,
      );
    },

    /**
     * 清除触发单击事件
     * 有双击事件有效
     */
    clearClick() {
      clearTimeout(this.delayClickTimerId);
      this.delayClickTimerId = null;
    },
    async emitLoaded() {
      const { treeData } = this;
      if (!treeData.length) return;

      await sleepIf(10000, () => this.visibleData.length);

      // 不一定准确
      this.$emit('loaded', treeData);
    },
    onSelectCheckChange(e) {
      let node = this.treeData.filter(val => val.groupId == e.groupId)[0];
      this.onCheckChange(node, !node.checked);
    },
    onSelectCheckVisible(e) {
      // console.log(this.treeData, e);
      let node = this.treeData.filter(val => val.vehicleId == e.vehicleId)[0];
      this.onCheckChange(node, !node.checked);
    },
  }
}
</script>

<style lang="scss" scoped>
.VirtualTree {
  position: relative;
  height: 100%;
  background: #fff;
  color: #606266;
}
.VirtualNode {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: #a2d2ff;
  }
}
.SelectedNode {
  background-color: #a2d2ff;
}
.TreeNodeCheckbox {
  margin-right: 8px;
}
.vNodeContent {
}

.empty-block {
  position: relative;
  min-height: 60px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.empty-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #909399;
  font-size: 14px;
}
</style>
